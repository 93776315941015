import { render, staticRenderFns } from "./goodssucc.vue?vue&type=template&id=6eae17c2&scoped=true&"
import script from "./goodssucc.vue?vue&type=script&lang=js&"
export * from "./goodssucc.vue?vue&type=script&lang=js&"
import style0 from "./goodssucc.vue?vue&type=style&index=0&id=6eae17c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eae17c2",
  null
  
)

export default component.exports