<template>
    <div>
       <Topbar></Topbar>
       <div class="succ-content">
            <div class="pay-top-font">
                <div class="pay-top-1">下单成功 感谢您的支持</div>
            </div>
            <div class="succ-body">
                <div class="body-left">
                    <div class="body-left-item" v-for="(item,index) in leftlist" :key="index">
                         <div>{{item.left}}:</div>
                         <div>￥{{item.right}}</div>
                    </div>
                </div>
                <div class="body-right">
                    <div class="body-right-item" v-for="(item,index) in rightlist" :key="index">
                         <div>{{item.left}}:</div>
                         <div>{{item.right}}</div>
                    </div>
                </div>
            </div>
            <div class="succ-bottom">
                <div class="see" @click="seedd">查看订单</div>
                <div class="go-on" @click="goon">继续逛逛></div>
            </div>
            
       </div>
    </div>
</template>
<script>
import Topbar from '../../PCshop/components/topbar.vue'
import { queryShopOrderDetail, shopOrderDelete } from "@/api/shop";
import { Toast, Dialog } from "vant";
export default {
    components:{
      Topbar
    },
    data(){
        return{
            orderId: 0,
            list:[
                {
                    left:'商品合计',
                    right:'443.00'
                },
                {
                     left:'运费',
                    right:'0.00'
                },
                {
                     left:'优惠劵',
                    right:'-50.00'
                },
                {
                     left:'实付',
                    right:'393.00'
                },
                {
                     left:'下单时间',
                    right:'2021-07-09 17:29:57'
                },
                {
                     left:'收货人',
                    right:'亮亮'
                },
                {
                     left:'联系电话',
                    right:'186****1688'
                },
                {
                     left:'收货地址',
                     right:'广东省深圳市福田区亮亮路15号亮亮小区2号楼502号'
                },
            ],
            leftlist:[],
            rightlist:[],
            detail: {},
        }
    },
    created(){
       this.orderId = this.$route.query.orderId;
       this.getQueryShopOrderDetail();
    },
    mounted(){
        this.leftlist=this.list.slice(0,4)
        this.rightlist=this.list.slice(-4)
    },
    methods:{
        async getQueryShopOrderDetail() {
        const res = await queryShopOrderDetail(this.orderId);
        this.detail = res.response;
        console.log(this.detail);
        this.leftlist[0].right= this.detail.GoodsPrice //商品总额
        this.leftlist[1].right= this.detail.Postage //运费
        this.leftlist[2].right= this.detail.DiscountMoney//折扣金额
        this.leftlist[3].right= this.detail.PayMoney //需付款
        this.rightlist[0].right= this.detail.CreateTime //付款时间
        this.rightlist[1].right= this.detail.ContactName //付款人
        this.rightlist[2].right= this.detail.ContactPhone //付款人电话
        this.rightlist[3].right= this.detail.Address  //付款人地址
      },
    
        seedd(){
             this.$router.push({ name: 'PCshop/my/shopmy'});
        },
        goon(){
             this.$router.push({ name: 'PCshop/goods/list'});
        }
    }
}
</script>
<style lang='scss' scoped>
   .succ-content{
        width: 1100px;
        height: 340px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin: 20px auto;
        padding-top: 30px;
         .pay-top-font{
             padding-bottom: 21px;
             text-align: center;
            .pay-top-1{
                        padding-left: 28px;
                        height: 28px;
                        line-height: 28px;
                        background-image: url('../../../assets/images/pay.png');
                        background-repeat: no-repeat;
                        background-position:41% 2px;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        margin: 0 auto;
                    }
        }
        .succ-body{
            display: flex;
            padding-left: 346px;
            .body-left{
             padding-right: 74px;
             border-right: 1px solid #EEEEEE;
                .body-left-item{
                    display: flex;
                    margin-top: 8px;
                }
            } 
            .body-right{
                margin-left: 74px;
                .body-right-item{
                    display: flex;
                    margin-top: 8px;
                } 
             }
            
        }
       .succ-bottom{
           display: flex;
           justify-content: center;
           margin-top: 37px;
           .see{
            cursor:pointer;
            width: 108px;
            height: 38px;
            background: #FA6639;
            text-align: center;
            line-height: 38px;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 16px;
           }
           .go-on{
               cursor:pointer;
               color: #61BCF1;
               font-weight: 400;
               font-size: 14px;
               margin-left: 22px;
               margin-top: 10px;
           }
       }
   }
</style>